<template>
    <div>
      <h4>
          Privacy Policy
      </h4>
    </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>