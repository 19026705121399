const EmailVerification = () => import("@/views/Auth/EmailVerification.vue");
const CompleteVerification = () =>
  import("@/views/Auth/CompleteVerification.vue");

const ChangePassword = () => import("@/views/Auth/ChangePassword.vue");
const Login = () => import("@/views/Auth/loginPage.vue");
const Register = () => import("@/views/Auth/registerPage.vue");
const ResetPassword = () => import("@/views/Auth/ResetPassword");
const ForgotPassword = () => import("@/views/Auth/ForgotPassword");
const VerifyEmail = () => import("@/views/Auth/VerifyEmail");
const ChangeUserPassword = () => import("@/views/Auth/ChangePasswordEmail");

// import router from "@/router";

// import Vuex store logics
// import store from "@/store";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      layout: "AppAuthLayout",
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      layout: "AppAuthLayout",
    },
  },

  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ResetPassword,
    meta: {
      layout: "AppAuthLayout",
    },
  },

  {
    path: "/reset-password",
    name: "reset-password",
    component: ForgotPassword,
    meta: {
      layout: "AppAuthLayout",
    },
  },

  {
    path: "/change-user-password",
    name: "change-user-password",
    component: ChangeUserPassword,
    meta: {
      layout: "DashboardLayout",
    },
  },

  {
    path: "/change-password",
    name: "change-password",
    component: ChangePassword,
    meta: {
      layout: "DashboardLayout",
    },
  },

  {
    path: "/verify-email",
    name: "verify-email",
    component: VerifyEmail,
    meta: {
      layout: "AppAuthLayout",
    },
  },

  {
    path: "/email-verification",
    name: "email-verification",
    component: EmailVerification,
    meta: {
      layout: "DashboardLayout",
    },
  },

  {
    path: "/complete-verification",
    name: "complete-verification",
    component: CompleteVerification,
    meta: {
      layout: "DashboardLayout",
    },
  },
];

// async function checkLoggedIn(to, from, next) {
//   var isAuthenticated = false;
//   if (localStorage.getItem("token")) isAuthenticated = true;
//   else isAuthenticated = false;
//   if (isAuthenticated) {
//     next({ path: "/dashboard" });
//   } else {
//     next();
//   }
// }

export default routes;
