import Vue from "vue";
import VueRouter from "vue-router";
import Auth from "./modules/auth";
import Dashboard from "./modules/dashboard";
import Home from "./modules/home";
import { checkTokenExpiration } from '@/plugins/auth';

Vue.use(VueRouter);

const routes = [];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes.concat(Auth, Dashboard, Home),
});

// router.beforeEach((to, from, next) => {
//   const publicPages = [
//     "/",
//     "/login",
//     "/register",
//     "/forgot-password",
//     "/reset-password",
//     "/terms-of-service",
//     "/privacy-policy",
//   ];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem("token");

//   if (authRequired && !loggedIn) {
//     next({path:"/dashboard"});
//   } else {
//     next();
//   }
// });

router.beforeEach((to, from, next) => {
  var isLoggedIn = localStorage.getItem('token');
  // Check if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    checkTokenExpiration();
    // Check if the user is authenticated
    if (!isLoggedIn) {
      // Check token expiration when the application loads
      // Redirect to the login page
      next({ path: '/login', query: { redirectFrom: to.fullPath } })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router;
