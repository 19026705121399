import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermsOfUse from "@/views/TermsOfUse.vue";

// import Vuex store logics
// import store from "@/store";

const routes = [
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    meta: {
      layout: "AppDefaultLayout",
    },
  },

  {
    path: "/terms-of-service",
    name: "terms-of-service",
    component: TermsOfUse,
    meta: {
      layout: "AppDefaultLayout",
    },
  },
];

export default routes;
