import { jwtDecode } from "jwt-decode";
import Vue from "vue";
import store from '@/store'

export function checkTokenExpiration() {
  const token = localStorage.getItem("token");

  if (token) {
    // Decode the token to get the expiration time
    const tokenData = jwtDecode(token);
    console.log(tokenData, "ommmmmo");

    // Check if the token has expired
    if (tokenData.exp * 1000 < Date.now()) {
      Vue.$toast.open({
        message: `JWT Token Expired, please, login`,
        type: "error",
        position: "top",
        // all of other options may go here
      });
      store.dispatch("auth/userLogout");
      // Token expired, redirect to login page
      window.location.href = "/login"; // Redirect to your login page
    }
  }
}
