const Dashboard = () => import("@/views/Dashboard/IndexPage.vue");
const Deposit = () => import("@/views/Dashboard/DepositFunds.vue");
const Withdrawal = () => import("@/views/Dashboard/WithdrawFunds.vue");
const HelpAndSupport = () => import("@/views/Dashboard/SupportPage.vue");

const Swap = () => import("@/views/Dashboard/SwapCurrency.vue");
const Staking = () => import("@/views/Dashboard/staking/IndexPage.vue")
const StakingDetails = () => import("@/views/Dashboard/staking/_ID.vue")
const PersonalInformation = () => import("@/views/Dashboard/UserProfile.vue")

const Refferals = () => import("@/views/Dashboard/ReferralPage.vue")
const TransactionHistory = () => import("@/views/Dashboard/TransactionHistory.vue")


const routes = [
  {
    path: "/",
    name: "dashboard",
    redirect: '/dashboard',
    component: Dashboard,
    meta: {
      layout: "DashboardLayout",
      parent: 'dashboard',
      header: "dashboard",
      requiresAuth: true
    },
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      layout: "DashboardLayout",
      parent: 'dashboard',
      header: "dashboard",
      requiresAuth: true
    },
  },

  {
    path: "/deposit",
    name: "deposit",
    component: Deposit,
    meta: {
      layout: "DashboardLayout",
      parent: 'deposit',
      header: "deposit",
      requiresAuth: true
    },
  },

  {
    path: "/withdrawal",
    name: "withdrawal",
    component: Withdrawal,
    meta: {
      layout: "DashboardLayout",
      parent: 'withdrawal',
      header: "withdrawal",
      requiresAuth: true
    },
  },

  {
    path: "/swap",
    name: "swap",
    component: Swap,
    meta: {
      layout: "DashboardLayout",
      parent: 'swap',
      header: "swap",
      requiresAuth: true
    },
  },

  {
    path: "/staking",
    name: "staking",
    component: Staking,
    meta: {
      layout: "DashboardLayout",
      parent: 'staking',
      header: "staking",
      requiresAuth: true
    },
  },

  {
    path: "/staking/:id",
    name: "staking-details",
    component: StakingDetails,
    meta: {
      layout: "DashboardLayout",
      parent: 'staking',
      header: "staking information",
      requiresAuth: true
    },
  },

  {
    path: "/personal-information",
    name: "personal-information",
    component: PersonalInformation,
    meta: {
      layout: "DashboardLayout",
      parent: 'personal-information',
      header: "personal information",
      requiresAuth: true
    },
  },

  {
    path: "/referral",
    name: "referral",
    component: Refferals,
    meta: {
      layout: "DashboardLayout",
      parent: 'referral',
      header: "referral",
      requiresAuth: true
    },
  },

  {
    path: "/transaction-history",
    name: "transaction-history",
    component: TransactionHistory,
    meta: {
      layout: "DashboardLayout",
      parent: 'transaction-history',
      header: "transaction history",
      requiresAuth: true
    },
  },

  {
    path: "/help-and-support",
    name: "help-and-support",
    component: HelpAndSupport,
    meta: {
      layout: "DashboardLayout",
      parent: 'help-and-support',
      header: "Help and Support",
      requiresAuth: true
    },
  },
];

// function checkLoggedIn(to, from, next) {
//   var isAuthenticated = false;
//   if (store.getters["auth/isLoggedIn"]) isAuthenticated = true;
//   else isAuthenticated = false;
//   if (!isAuthenticated) {
//     next();
//   } else {
//     next({
//       query: { redirectFrom: to.fullPath },
//     });
//   }
// }

export default routes;
